import React, { useState, useEffect, useMemo } from 'react'
import { getStations } from '../station/stationService';
import DeleteModal from '../../components/DeleteModal';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Col, Row, Modal, Form, Button } from 'react-bootstrap';
import Table from '../../components/Table';
import Helper from '../../Utils/helper';
import toast from 'react-hot-toast';
import Navbar from '../../components/navbar';
import { getOutages, deleteOutage } from './outageService';

const Outage = () => {

    const sizePerPageList = [
        {
            text: '10',
            value: 10,
        },
        {
            text: '20',
            value: 20,
        },
    ];

    const [loginInUser, setLoginInUser] = useState(JSON.parse(localStorage.getItem('loginInUser')));
    const baseUrl = process.env.REACT_APP_API_URL;
    const ActionColumn = (row) => {
        let rowOriginal = row.row.original;
        return (
            <>
                <Link className="action-icon" to={`/outage/${rowOriginal?.rid}`} /* onClick={() => OnEditPopUp(rowOriginal)} */>
                    {' '}
                    <i className="fas fa-edit"></i>
                </Link>
                <Link className="action-icon" onClick={() => OnDeletePopUp(rowOriginal)}>
                    {' '}
                    <i className="fas fa-trash-alt"></i>
                </Link>
            </>
        );
    };
    const LinkColumn = (row) => {
        let rowOriginal = row.row.original;
        return (
            rowOriginal?.resolutionFileUrl?
            <>
                <a style={{color:"#007bff"}} href={`${baseUrl+rowOriginal?.resolutionFileUrl}`} target='_blank'>
                    View File
                </a>
            </>:<></>
        );
    };


    const [searchFields, setSearchFields] = useState({
        stationCode: loginInUser?.stationCode || "",
        fromDate: null,
        toDate: null,
    });

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setSearchFields((prev) => ({
            ...prev,
            [name]: value,

        }));
    };
    // get all columns
    const columns = [
        {
            Header: 'Date',
            accessor: 'outageDate',
            sort: true,
            Cell: ({ value }) => Helper.formatDate(value),
        },


        {
            Header: 'Station',
            accessor: 'station',
            sort: true,

        },
        {
            Header: 'Stage',
            accessor: 'stage',
            sort: true,
        },
        {
            Header: 'Unit',
            accessor: 'unit',
            sort: true,
        },
        {
            Header: 'Outage',
            accessor: 'outageType',
            sort: true,

        },
        {
            Header: 'Area',
            accessor: 'area',
            sort: true,
        },
        {
            Header: 'Sub Area',
            accessor: 'subArea',
            sort: true,

        },
        {
            Header: 'Loss in MU',
            accessor: 'lossInMU',
            sort: true,
        },
        {
            Header: 'Trip Date Time',
            accessor: 'tripDateTime',
            sort: true,
            Cell: ({ value }) => value ? Helper.formatDate(value) : "NA",


        },
        {
            Header: 'File',
            accessor: 'resolutionFileUrl',
            Cell: LinkColumn,
        },

        {
            Header: 'Action',
            accessor: 'id',
            Cell: ActionColumn,
        },

    ];

    const todayDate = new Date().toISOString().slice(0, 10);
    const [stations, setStations] = useState([]);
    const [outages, setOutages] = useState([]);

    const [outage, setOutage] = useState({
        "rid": 1,
        "outageDate": "07 Jun 2024",
        "stationCode": "T01",
        "station": "Tamnar Thermal",
        "stage": "Stage1",
        "unit": "Unit1",
        "outageType": "Planned Outage",
        "area": "Boiler Maintenance",
        "subArea": "Overhauling",
        "lossInMU": 15,
        "tripDateTime": "2024-06-07 12:15",
        "syncDateTime": "2024-06-08 09:20",
        "resolutionFileUrl":""
    });



    const [deleteModal, setDeleteModal] = React.useState(false);
    const handleCloseDelete = () => setDeleteModal(false);

    const fetchStations = async () => {
        try {
            const response = await getStations();
            if (response.data.success) {
                let data = response.data.data;
                console.log(data);
                if (loginInUser?.stationCode) {
                    data = data?.filter((station) => station.stationCode === loginInUser.stationCode);
                }
                setStations(data);
            }

        } catch (e) {
            console.log(e);
        }
    }

    const fetchOutages = async () => {
        try {
            const response = await getOutages(searchFields?.stationCode, searchFields?.fromDate, searchFields?.toDate);
            if (response.data.success) {
                const data = response.data.data;
                console.log(data);
                setOutages(data);
            }

        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        fetchStations();
        fetchOutages();
    }, [])



    const OnDeletePopUp = (rowOriginal) => {
        setOutage(rowOriginal);
        setDeleteModal(true);
    }



    const OnDelete = async () => {
        const toastId = toast.loading('Please wait...');
        try {
            const response = await deleteOutage(outage.rid);
            if (response.status === 200) {
                await fetchOutages();
                toast.success('Outage deleted!', { id: toastId });
            }
        } catch (e) {
            console.log(e);
            toast.error(e?.response?.data?.message || e?.data?.message, { id: toastId })
        }
        finally {
            handleCloseDelete();
        }
    }

    return (
        <>
            <Navbar />
            <div className='content' style={{ width: '100%' }}>
                <div id="page-containter">
                    <Row>
                        <Col xs={12}>
                            <Card className='custom-card'>
                                <Card.Body>
                                    <Row className='mb-2'>
                                        <Col>
                                            <h1 className='page-title'>Outage</h1>
                                        </Col>
                                        <Col sm={4}>
                                            <div className="text-sm-end btn-employee">
                                                <Link to={`/outage/add`} className="btn btn-primary float-right transformy39px">
                                                    <i className="dripicons-plus me-2"></i> Add Outage
                                                </Link>

                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Row>
                                                <Col>
                                                    <Form.Group controlId="formStationID" className='mb-2'>
                                                        <Form.Select
                                                            value={searchFields?.stationCode}
                                                            name="stationCode"
                                                            onChange={handleChangeSearch}
                                                        >
                                                            <option value={""}
                                                                disabled={loginInUser?.stationCode ? true : false}
                                                            >All Stations</option>
                                                            {
                                                                stations?.filter(x => x.energyType == "Thermal")?.map((station) => (
                                                                    <option key={station.stationCode} value={station.stationCode}>{station.stationName}</option>
                                                                ))
                                                            }
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    {/* input type of date fornmat */}
                                                    <Form.Group controlId="formReportDate" className='mb-2'>
                                                        <Form.Control
                                                            type="date"
                                                            placeholder="From Date"
                                                            value={searchFields.fromDate}
                                                            name="fromDate"
                                                            onChange={handleChangeSearch}
                                                            max={searchFields?.toDate}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group controlId="formReportDate" className='mb-2'>
                                                        <Form.Control
                                                            type="date"
                                                            placeholder="To Date"
                                                            value={searchFields.toDate}
                                                            name="toDate"
                                                            onChange={handleChangeSearch}
                                                            min={searchFields?.fromDate}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Button variant="primary " className="btn-md"
                                                        onClick={() => {
                                                            fetchOutages();
                                                        }}>Search</Button>

                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>

                                    <Table
                                        columns={columns}
                                        data={outages}
                                        pageSize={10}
                                        sizePerPageList={sizePerPageList}
                                        isSortable={true}
                                        pagination={true}
                                        isSearchable={false}
                                        theadClass="table-light"
                                        searchBoxClass="mb-5"
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>

                {deleteModal && <DeleteModal modal={deleteModal} toggle={handleCloseDelete} type={'Outage'}
                    handleDelete={OnDelete} userName={outage.station + (outage.outageType?`(${outage.outageType})`:'') + "-" + Helper.formatDate(outage?.outageDate)} />}
            </div>

        </>
    )
}

export default React.memo(Outage);