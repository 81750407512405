import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Navbar from '../../components/navbar';
import { useParams, useNavigate, Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { addOutage, updateOutage, getOutage, getStationStageUnitDropdown, uploadFile } from './outageService';
import Helper from '../../Utils/helper';

const AddOutage = () => {
    const { outageId } = useParams();
    const navigate = useNavigate();
    const [loginUser, setLoginUser] = useState(JSON.parse(localStorage.getItem('loginInUser')) || {});
    const baseUrl = process.env.REACT_APP_API_URL;

    const [dropdownData, setDropdownData] = useState({
        stations: [],
        stages: []
    });

    const [error, setError] = useState(false);
    const [fileUploading, setFileUploading] = useState(false);

    const [outageData, setOutageData] = useState({
        outageDate: "",
        stationCode: loginUser?.stationCode || "",
        station: "",
        stage: "",
        unit: "",
        outageType: "",
        area: "",
        subArea: null, // Optional
        lossInMU: 0,
        tripDateTime: null, // Optional
        syncDateTime: null, // Optional
        resolution: "", // New field
        resolutionFileUrl: "" // New field
    });


    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "stationCode") {
            setOutageData(prevState => ({
                ...prevState,
                stationCode: value,
                stage: "",
                unit: ""
            }));
            return;
        } else if (name === "stage") {
            setOutageData(prevState => ({
                ...prevState,
                stage: value,
                unit: ""
            }));
            return;
        } else if (name === "lossInMU" && value < 0) {
            setOutageData(prevState => ({
                ...prevState,
                lossInMU: 0
            }));
            return;
        }

        setOutageData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }


    const fetchOutageData = async () => {
        try {
            const response = await getOutage(outageId);
            if (response.data.success) {
                const data = response.data.data;
                data.outageDate = data.outageDate.split('T')[0];
                setOutageData(data);
            }
        } catch (e) {
            console.error(e);
            toast.error(e?.response?.data?.message || e?.data?.message);
        }
    };

    const fetchDropdownData = async () => {
        try {
            const response = await getStationStageUnitDropdown();
            if (response.data.success) {
                let data = response.data.data;
                if (loginUser?.stationCode) {
                    data = data?.filter(i => i.stationCode === loginUser.stationCode);
                }
                setDropdownData({
                    stations: data,
                });
            }
        } catch (e) {
            console.error(e);
            toast.error(e?.response?.data?.message || e?.data?.message || "Failed to fetch dropdown data");
        }
    };

    useEffect(() => {
        fetchDropdownData();
        if (outageId !== "add") {
            fetchOutageData();
        }
    }, [outageId]);

    const handleSubmit = async () => {
        if (!outageData.outageDate
            || !outageData.stationCode
            || !outageData.stage
            || !outageData.unit) {
            setError(true);
            return;
        }

        const toastId = toast.loading('Please wait...');
        console.log(outageData);
        try {
            let response;
            if (outageId === "add") {
                response = await addOutage(outageData);
            } else {
                response = await updateOutage(outageId, outageData);
            }
            if (response.data.success) {
                toast.success('Outage saved!', { id: toastId });
                navigate('/outage');
            }
        } catch (e) {
            console.error(e);
            toast.error(e?.response?.data?.message || e?.data?.message, { id: toastId });
        }
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];

        if (!file) {
            return;
        }
        setFileUploading(true);
        const formData = new FormData();
        formData.append("file", file, file.name);

        try {
            const response = await uploadFile(formData);
            console.log(response);
            if (response.data.success) {
                setOutageData(prevState => ({
                    ...prevState,
                    resolutionFileUrl: response.data.data.fileUrl
                }));
                setFileUploading(false);
            } else {
                toast.error("File upload failed.");
                setFileUploading(false);
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            toast.error("File upload failed.");
            setFileUploading(false);
        }
    };


    return (
        <>
            <Navbar />
            <div className="container main-body">
                <Row>
                    <Col md={10}>
                        <h1>{(outageId === "add" ? "Add " : "Update ") + "Outage"}</h1>
                    </Col>
                    <Col md={2}>
                        <Link to={`/outage`} className="btn btn-secondary btnwidth ml-2 move-right">
                            Back
                        </Link>
                    </Col>
                </Row>

                <div id='page-container'>
                    <Form>
                        <Col>
                            <Row>
                                <Col className='col-md-3 col-sm-12'>
                                    <Form.Group controlId="formOutageDate" className='mb-2'>
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            value={outageData.outageDate}
                                            name="outageDate"
                                            onChange={handleChange}
                                            max={Helper.YesterdayDate()}
                                        />
                                        <small className='error'>
                                            {error && !outageData.outageDate && "Outage date is required"}
                                        </small>
                                    </Form.Group>
                                </Col>

                                <Col className='col-md-3 col-sm-12'>
                                    <Form.Group controlId="formStationCode" className='mb-2'>
                                        <Form.Label>Station</Form.Label>
                                        <Form.Select
                                            value={outageData.stationCode}
                                            name="stationCode"
                                            onChange={handleChange}
                                        >
                                            <option value="" disabled>Select Station</option>
                                            {dropdownData?.stations?.filter(x => x.energyType == "Thermal")?.map((station) => (
                                                <option key={station.stationCode} value={station.stationCode}>{station.stationName}</option>
                                            ))}
                                        </Form.Select>
                                        <small className='error'>
                                            {error && !outageData.stationCode && "Station code is required"}
                                        </small>
                                    </Form.Group>
                                </Col>

                                <Col className='col-md-3 col-sm-12'>
                                    <Form.Group controlId="formStage" className='mb-2'>
                                        <Form.Label>Stage</Form.Label>
                                        <Form.Select
                                            value={outageData.stage}
                                            name="stage"
                                            onChange={handleChange}
                                        >
                                            <option value="">Select stage</option>
                                            {dropdownData?.stations?.find(i => i.stationCode === outageData?.stationCode)?.stages?.map((stage) => (
                                                <option key={stage.stageID + stage.stageName} value={stage.stageName}>{stage.stageName}</option>
                                            ))}
                                        </Form.Select>
                                        <small className='error'>
                                            {error && !outageData.stage && "Stage is required"}
                                        </small>
                                    </Form.Group>
                                </Col>

                                <Col className='col-md-3 col-sm-12'>
                                    <Form.Group controlId="formUnit" className='mb-2'>
                                        <Form.Label>Unit</Form.Label>
                                        <Form.Select
                                            value={outageData.unit}
                                            name="unit"
                                            onChange={handleChange}
                                        >
                                            <option value="">Select Unit</option>
                                            {
                                                dropdownData?.stations?.find(i => i.stationCode === outageData?.stationCode)?.stages?.find(i => i.stageName === outageData?.stage)?.units?.map((unit) => (
                                                    <option key={unit.unitID + unit.unitName} value={unit.unitName}>{unit.unitName}</option>
                                                ))
                                            }
                                        </Form.Select>
                                        <small className='error'>
                                            {error && !outageData.unit && "Unit is required"}
                                        </small>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col className='col-md-3 col-sm-12'>
                                    <Form.Group controlId="formOutageType" className='mb-2'>
                                        <Form.Label>Outage Type</Form.Label>
                                        <Form.Select
                                            value={outageData.outageType}
                                            name="outageType"
                                            onChange={handleChange}
                                        >
                                            <option value="" disabled>Select Outage Type</option>
                                            <option value="Forced Outage">Forced Outage</option>
                                            <option value="Planned Outage">Planned Outage</option>
                                            <option value="Grid Outage">Grid Outage</option>
                                            <option value="Fuel Outage">Fuel Outage</option>
                                            <option value="Other Outage">Other Outage</option>
                                        </Form.Select>
                                        {/* <small className='error'>
                                            {error && !outageData.outageType && "Outage type is required"}
                                        </small> */}
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-sm-12'>
                                    <Form.Group controlId="formArea" className='mb-2'>
                                        <Form.Label>Outage Area</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter area"
                                            value={outageData.area}
                                            name="area"
                                            onChange={handleChange}
                                        />
                                        {/* <small className='error'>
                                            {error && !outageData.area && "Area is required"}
                                        </small> */}
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-sm-12'>
                                    <Form.Group controlId="formSubArea" className='mb-2'>
                                        <Form.Label>Outage Sub Area</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter sub area"
                                            value={outageData.subArea}
                                            name="subArea"
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>

                                <Col className='col-md-3 col-sm-12'>
                                    <Form.Group controlId="formLossInMU" className='mb-2'>
                                        <Form.Label>Loss In MU</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter loss in MU"
                                            value={outageData.lossInMU}
                                            name="lossInMU"
                                            onChange={handleChange}
                                        />
                                        {/* <small className='error'>
                                            {error && !outageData.lossInMU && "Loss in MU is required"}
                                        </small> */}
                                    </Form.Group>
                                </Col>

                                <Col className='col-md-3 col-sm-12'>
                                    <Form.Group controlId="formTripDateTime" className='mb-2'>
                                        <Form.Label>Trip Date Time</Form.Label>
                                        <Form.Control
                                            type="datetime-local"
                                            value={outageData.tripDateTime}
                                            name="tripDateTime"
                                            onChange={handleChange}
                                            max={Helper.YesterdayDate()}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-sm-12'>
                                    <Form.Group controlId="formSyncDateTime" className='mb-2'>
                                        <Form.Label>Sync Date Time</Form.Label>
                                        <Form.Control
                                            type="datetime-local"
                                            value={outageData.syncDateTime}
                                            name="syncDateTime"
                                            onChange={handleChange}
                                            max={Helper.YesterdayDate()}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='col-md-6 col-sm-12'>
                                    <Form.Group controlId="formResolution" className='mb-2'>
                                        <Form.Label>Resolution</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Enter resolution"
                                            value={outageData.resolution}
                                            name="resolution"
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col className='col-md-6 col-sm-12'>
                                    <Form.Group controlId="formResolutionFileUrl" className='mb-2'>
                                        <Form.Label>Upload Resolution File</Form.Label>
                                        <Form.Control
                                            type="file"
                                            onChange={handleFileUpload}
                                        />

                                    </Form.Group>

                                </Col>
                                <Col className='col-md-6 col-sm-12'>
                                    <p></p>
                                    <br />
                                    {outageData.resolutionFileUrl && (
                                        <a href={baseUrl + outageData.resolutionFileUrl} target="_blank" style={{ color: "#007bff" }}>
                                            <u>View File</u>
                                        </a>
                                    )}
                                </Col>
                            </Row>

                            {fileUploading ?
                                <Button variant="primary btnwidth">
                                Uploading...
                            </Button> :
                                <Button variant="primary btnwidth" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            }
                        </Col>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default AddOutage;
