import React, { useState } from 'react'
import { login as loginAPI } from './authService';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios';
import JPLLogo from '../../assests/jpl-logo.png'

const ForgotPassword = () => {
    const loginInitialState = {
        email: '',
    }
    const [login, setLogin] = useState(loginInitialState);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    // onchange function for above state
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLogin({
            ...login,
            [name]: value
        });
    };

    // onsubmit function for above state
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!login.email) {
            setError(true)
            return;
        }
        else {
            const reqBody = {
                email: login.email,
            }
            const toastId = toast.loading('loading ...');
            try {
                const response = await axios.post(`${process.env.REACT_APP_AUTH_API_URL}/auth/forgotpassword`, reqBody, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.status === 200) {
                    toast.success('Password reset link sent to your email', { id: toastId });
                    setSuccess(true);
                }

            } catch (e) {
                console.log(e);
                toast.error(e?.response?.data?.message || e?.data?.message, { id: toastId });
                setSuccess(false);
                return;
            }
        }


    };

    return (
        <>
            <div class="container-fluid bg">
                <div class="row login-outer">
                    <div class="col-md-5 col-sm-12 login-left">
                        <img src="static/plant-banner.png" loading="lazy" class="login-left-img" alt="login" />

                    </div>
                    <div class="col-md-7 col-sm-12">
                        <div className='login-log'>
                            <img src={JPLLogo} loading='lazy' alt='JPL Logo' className='jpl-logo' />
                        </div>

                        <div class="card login-box">
                            {
                                success ?
                                    <div class="card-body">
                                        <h2 class="card-title
                                        black-color">Success</h2>
                                        <p>Password reset link sent to your email</p>
                                        <Link to={'/login'} class="btn btn-link"><u>Login</u></Link>
                                    </div>
                                    :

                                    <div class="card-body">
                                        <h2 class="card-title  black-color">Plant Live</h2>
                                        <p>Real Time Analytics App</p>

                                        <div class="mb-3">
                                            <input type="email" placeholder="Enter Email"
                                                name='email' required value={login.email}
                                                onChange={(e) => {
                                                    e.target.value = e.target.value.replace(/[^a-zA-Z0-9@.]/g, '').trim()
                                                    handleInputChange(e)
                                                }}
                                                class="form-control black-color" id="email"
                                                aria-describedby="emailHelp" />
                                            <small className='error ml-2'>{(error && !login.email) ? "Email is required" : ""}</small>
                                        </div>
                                        <div class="mb-3 d-flex justify-content-between">
                                            <button type='button' onClick={handleSubmit} class="btn ml-1 btn-primary ">Reset Password</button>

                                            <Link to={'/login'} class="btn btn-link"><u>Login</u></Link>
                                        </div>

                                    </div>
                            }
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default ForgotPassword