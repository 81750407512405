import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar';
import { Form, Row, Col, Button, Table } from 'react-bootstrap';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { AddDailyGenerations, getDailyGenerationDetail, getDailyGenerationDetailById, getPreviousCumGen } from './dailyGenerationService';
import toast from 'react-hot-toast';
import { getStationStageUnitDropdown } from '../station/stationService';
import Helper from '../../Utils/helper';

const AddDailyGeneration = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    //const location = useLocation();
    const [loginUser, setLoginUser] = useState(JSON.parse(localStorage.getItem('loginInUser')));
    const [energyType, setEnergyType] = useState('Thermal');
    const [generationData, setGenerationData] = useState({
        rid: 0,
        reportDate: Helper.YesterdayDate(),
        stationCode: "",
        station: "",
        stage: "",
    });

    const [previousGeneration, setPreviousGeneration] = useState(null);

    const [stageData, setStageData] = useState(/* {
        dayGen: 0,
        cumGen_CY: 0,
        cumGen_PY: 0,
        cumGen_CY_CM: 0,
        cumGen_PY_CM: 0,
        export_MU: 0,
        consumptionColony_MU: 0,
        apc: 0,
        apcPer: 0,
    } */ null);

    const [previousStageData, setPreviousStageData] = useState(null);

    const [unitData, setUnitData] = useState([
        /* {
            unit: "",
            dayGen: 0,
            cumGen_CY: 0,
            cumGen_PY: 0,
            cumGen_CY_CM: 0,
            cumGen_PY_CM: 0,
        }, */
    ]);

    const [previousUnitData, setPreviousUnitData] = useState([]);

    const [previousCumGen, setPreviousCumGen] = useState(null);

    const [error, setError] = useState(false);

    const handleGenerationDataChange = (e) => {
        const { name, value } = e.target;
        if (name === 'stationCode') {
            const selectedOption = e.target.options[e.target.selectedIndex];
            const stationName = selectedOption.getAttribute('data-stationname');
            const energyType = selectedOption.getAttribute('data-energytype');
            setEnergyType(energyType);
            setGenerationData(prevState => ({
                ...prevState,
                stationCode: value,
                station: stationName,
                stage: energyType == "RE" ? null : prevState.stage,
            }));

            setStageData("");
            setUnitData([]);
        } else {
            setGenerationData(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleStageDataChange = (e) => {
        const { name, value } = e.target;
        if (isNaN(value)) return;

        console.log(generationData.reportDate, value, previousGeneration, "stageData");

        setStageData(prevState => ({
            ...prevState,
            ['dayGen']: value,
        }));
    };

    const handleStageChange = (e) => {
        const { name, value } = e.target;
        if (isNaN(value)) return;

        let apc = 0;
        let apcPer = 0;
        const stage = { ...stageData };

        if (name == 'export_MU') {
            apc = Number(stage.dayGen) - Number(value) - Number(stage.consumptionColony_MU);
            apcPer = (Number(apc) / Number(stage.dayGen));
        } else {
            apc = Number(stage.dayGen) - Number(value) - Number(stage.export_MU);
            apcPer = (Number(apc) / Number(stage.dayGen));
            apcPer = apcPer * 100;
        }

        setStageData((prev) => ({
            ...prev,
            [name]: value,
            'apc': apc,
            'apcPer': apcPer?.toFixed(2),
        }));
    }

    useEffect(() => {

        if (stageData?.dayGen || stageData?.export_MU || stageData?.consumptionColony_MU) {

            let apc = 0;
            let apcPer = 0;
            const stage = { ...stageData };

            apc = Number(stage?.dayGen || 0) - (Number(stage?.export_MU || 0) + Number(stage?.consumptionColony_MU || 0));
            apcPer = (Number(apc) / Number(stage?.dayGen));
            apcPer = apcPer * 100;

            setStageData((prev) => ({
                ...prev,
                'apc': apc?.toFixed(2),
                'apcPer': apcPer?.toFixed(2),
            }));
        }
    }, [stageData?.dayGen, stageData?.export_MU, stageData?.consumptionColony_MU])

    const handleUnitDataChange = (index, e) => {
        const { name, value } = e.target;
        if (isNaN(value)) return;
        console.log(generationData.reportDate, value, previousGeneration, "stageData");
        const newUnitData = [...unitData];
        newUnitData[index]['dayGen'] = value;
        setUnitData(newUnitData);

        const dayGen = newUnitData.reduce((acc, curr) => acc + parseFloat(curr.dayGen), 0);
        setStageData(prevState => ({
            ...prevState,
            dayGen: dayGen,
        }));

    };
    const [dropdownData, setDropdownData] = useState({
        stations: [],
        stages: []
    });

    const fetchDetails = async (reportDate, stationCode, stage) => {
        try {
            const response = await getDailyGenerationDetail(reportDate, stationCode, stage);
            if (response.data.success) {
                const { data } = response.data;
                console.log(data, "Add Gen data")
                data.currentDay.reportDate = data.currentDay.reportDate.split('T')[0];
                setGenerationData(data.currentDay);
                setStageData(data.currentDay.stageData);
                setUnitData(data.currentDay.unitData);
                setPreviousGeneration(data.previousDay);
                setPreviousStageData(data.previousDay.stageData);
                setPreviousUnitData(data.previousDay.unitData);
                setPreviousCumGen(data.previousYearCum);
            }
        } catch (err) {
            console.log(err);
            toast.error(err?.response?.data?.message || err?.data?.message || 'Something went wrong');
        }
    };

    const fetchPreviousDetails = async (reportDate, stationCode, stage) => {
        try {
            let newDate = new Date(reportDate + 'T00:00:00Z'); // Ensure the date is in UTC

            // Subtract one day (in milliseconds)
            newDate.setUTCDate(newDate.getUTCDate() - 1);

            // Format the newDate back to 'YYYY-MM-DD' format
            let year = newDate.getUTCFullYear();
            let month = ('0' + (newDate.getUTCMonth() + 1)).slice(-2); // Months are zero-indexed
            let day = ('0' + newDate.getUTCDate()).slice(-2);
            let formattedDate = `${year}-${month}-${day}`;

            const response = await getDailyGenerationDetail(formattedDate, stationCode, stage);
            if (response.data.success) {
                // console.log('Data fetched successfully');
                const { data } = response.data;
                setPreviousGeneration(data);
                data.stageData.dayGen = 0;
                data.stageData.export_MU = 0;
                data.stageData.consumptionColony_MU = 0;
                data.stageData.apc = 0;
                data.stageData.apcPer = 0;
                setStageData(data.stageData);
                data.unitData.forEach(i => i.dayGen = 0);
                setUnitData(data.unitData);
            }
        } catch (err) {
            console.log(err);
            toast.error(err?.response?.data?.message || err?.data?.message || 'Something went wrong');
        }
    };

    const fetchPreviousCumGen = async (reportDate, stationCode, stage) => {
        try {

            const response = await getPreviousCumGen(reportDate, stationCode, stage);
            if (response.data.success) {
                // console.log('Data fetched successfully');
                const { data } = response.data;
                setPreviousCumGen(data);
            }
        } catch (err) {
            console.log(err);
            toast.error(err?.response?.data?.message || err?.data?.message || 'Something went wrong');
        }
    }

    const fetchDetailsbyId = async (id) => {
        try {
            const response = await getDailyGenerationDetailById(id);
            if (response.data.success) {
                // console.log('Data fetched successfully');
                const { data } = response.data;
                data.currentDay.reportDate = data.currentDay.reportDate.split('T')[0];
                setGenerationData(data.currentDay);
                setEnergyType(data.currentDay.energyType);
                setStageData(data.currentDay.stageData);
                setUnitData(data.currentDay.unitData);
                setPreviousGeneration(data.previousDay);
                setPreviousStageData(data.previousDay.stageData);
                setPreviousUnitData(data.previousDay.unitData);
                setPreviousCumGen(data.previousYearCum);

            }
        } catch (err) {
            console.log(err);
            toast.error(err?.response?.data?.message || err?.data?.message || 'Something went wrong');
        }
    };


    useEffect(() => {
        // console.log(location.state);
        if (id != "add") {
            fetchDetailsbyId(id);
        }
    }, [id]);

    const fetchDropdownData = async () => {
        try {
            const response = await getStationStageUnitDropdown();
            if (response.data.success) {
                let data = response.data.data;
                if (loginUser?.stationCode) {
                    data = data.filter(i => i.stationCode === loginUser.stationCode);
                }
                setDropdownData({
                    stations: data,
                });
            }
        } catch (e) {
            console.log(e);
            toast.error(e?.response?.data?.message || e?.data?.message || "Failed to fetch dropdown data");
        }
    };

    useEffect(() => {
        fetchDropdownData();
    }, []);

    useEffect(() => {

        if (generationData.reportDate && generationData.stationCode && generationData.stage && energyType == "Thermal" && id == "add") {
            fetchDetails(generationData.reportDate, generationData.stationCode, generationData.stage);
        }
        else if (generationData.reportDate && generationData.stationCode && energyType == "RE" && id == "add") {
            fetchDetails(generationData.reportDate, generationData.stationCode, null);
        }

    }, [generationData.reportDate, generationData.stationCode, generationData.stage])

    const handleSubmit = async () => {

        try {

            const unitDataBody = [...unitData];

            const reqBody = {
                "reportDate": generationData.reportDate,
                "stationCode": generationData.stationCode,
                "energyType": energyType,
                "stage": generationData.stage,
                "stageData": {
                    "dayGen": stageData.dayGen,
                    "cumGen_CY": Number(stageData.dayGen) + Number(previousStageData?.cumGen_CY),
                    "cumGen_PY": previousCumGen?.stagePreviousCumGenPY,
                    "cumGen_CY_CM": Number(stageData.dayGen) + Number(previousStageData?.cumGen_CY_CM),
                    "cumGen_PY_CM": previousCumGen?.stagePreviousCumGenPYCM,
                    "export_MU": stageData.export_MU,
                    "consumptionColony_MU": stageData.consumptionColony_MU,
                    "apc": stageData.apc,
                    "apcPer": stageData.apcPer,
                },
                "unitData": unitDataBody.map((i,index) => ({
                    "unit": i.unit,
                    "dayGen": i.dayGen,
                    "cumGen_CY": Number(i.dayGen) + Number(previousUnitData[index]?.cumGen_CY),
                    "cumGen_PY": previousCumGen?.previousUnitdata[index]?.unitPreviousCumGenPY,
                    "cumGen_CY_CM": Number(i.dayGen) + Number(previousUnitData[index]?.cumGen_CY_CM),
                    "cumGen_PY_CM": previousCumGen?.previousUnitdata[index]?.unitPreviousCumGenPYCM,
                }))
            }


            const res = await AddDailyGenerations(reqBody);
            if (res.data.success) {
                navigate('/daygen');
            }

        }
        catch (e) {
            console.log(e);
            toast.error(e?.response?.data?.message || e?.data?.message || "Failed to save");
        }

    }

    return (
        <>
            <Navbar />
            <div className="container main-body">
                <Row className='mb-2'>
                    <Col md={10}>
                        <h1>{id === "add" ? "Add " : "Update "} {" Daily Generation"}</h1>
                    </Col>
                    <Col md={2}>
                        <Link to={`/daygen`} className="btn btn-secondary btnwidth ml-2 move-right">
                            Back
                        </Link>
                    </Col>
                </Row>

                <div id='page-container'>
                    <Row>

                        <Col className='col-md-3 col-sm-12'>
                            <Form.Group controlId="formStationCode" className='mb-2'>
                                <Form.Label>Date</Form.Label>
                                <Form.Control
                                    value={generationData.reportDate}
                                    name="reportDate"
                                    type='date'
                                    max={Helper.YesterdayDate()}
                                    onChange={handleGenerationDataChange}
                                    disabled={id != 'add'}
                                >
                                </Form.Control>
                                <small className='error'>
                                    {error && !generationData.reportDate && "Report Date is required"}
                                </small>
                            </Form.Group>
                        </Col>

                        <Col className='col-md-3 col-sm-12'>
                            <Form.Group controlId="formStationCode" className='mb-2'>
                                <Form.Label>Station</Form.Label>
                                <Form.Select
                                    value={generationData.stationCode}
                                    name="stationCode"
                                    disabled={id != 'add'}
                                    onChange={handleGenerationDataChange}
                                >
                                    <option value="" disabled>Select Station</option>
                                    {dropdownData?.stations?.map((station) => (
                                        <option key={station.stationCode} data-stationname={station.stationName} data-energytype={station.energyType} value={station.stationCode}>{station.stationName}</option>
                                    ))}
                                </Form.Select>
                                <small className='error'>
                                    {error && !generationData.stationCode && "Station code is required"}
                                </small>
                            </Form.Group>
                        </Col>

                        {energyType == "Thermal" && <Col className='col-md-3 col-sm-12'>
                            <Form.Group controlId="formStage" className='mb-2'>
                                <Form.Label>Stage</Form.Label>
                                <Form.Select
                                    value={generationData.stage}
                                    name="stage"
                                    onChange={handleGenerationDataChange}
                                    disabled={id != 'add'}
                                >
                                    <option value="" disabled>Select stage</option>
                                    {dropdownData?.stations?.find(i => i.stationCode === generationData?.stationCode)?.stages?.map((stage) => (
                                        <option key={stage.stageID + stage.stageName} value={stage.stageName}>{stage.stageName}</option>
                                    ))}
                                </Form.Select>
                                {/* <small className='error'>
                                    {error && !generationData.stage && "Stage is required"}
                                </small> */}
                            </Form.Group>
                        </Col>}
                    </Row>

                    {unitData && stageData &&
                        <>
                            <Table striped="columns" style={{ fontSize: '0.8rem' }}>
                                <thead>
                                    <tr>
                                        <th>Unit</th>
                                        <th>Daily Generation in MU</th>
                                        <th>Cum Generation CY in MU</th>
                                        <th>Cum Generation CM in MU</th>
                                        <th>Cum Generation PY in MU</th>
                                        <th>Cum Generation CM PY in MU</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {unitData.map((unit, index) => (
                                        <tr key={index}>
                                            <td>{unit.unit}</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="dayGen"
                                                    value={unitData[index].dayGen}
                                                    onChange={(e) => handleUnitDataChange(index, e)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="cumGen_CY"
                                                    disabled={true}
                                                    value={Helper.ReturnCorrectValue(generationData.reportDate, unitData[index].dayGen || 0, previousUnitData[index]?.cumGen_CY || 0)}
                                                // onChange={(e) => handleUnitDataChange(index, e)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="cumGen_CY_CM"
                                                    disabled={true}
                                                    // value={Helper.formatNumber(Number(previousUnitData[index].cumGen_CY_CM )+ Number(unitData[index].dayGen))}
                                                    value={Helper.ReturnCorrectValue(generationData.reportDate, unitData[index].dayGen || 0, previousUnitData[index]?.cumGen_CY_CM || 0)}
                                                // onChange={(e) => handleUnitDataChange(index, e)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="cumGen_PY"
                                                    disabled={true}
                                                    value={Helper.formatNumber(previousCumGen?.previousUnitdata[index]?.unitPreviousCumGenPY)}
                                                // onChange={(e) => handleUnitDataChange(index, e)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="cumGen_PY_CM"
                                                    disabled={true}
                                                    value={Helper.formatNumber(previousCumGen?.previousUnitdata[index]?.unitPreviousCumGenPYCM)}
                                                // onChange={(e) => handleUnitDataChange(index, e)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td>{energyType == "Thermal" ? "Stage" : "Solar Installation"}</td>
                                        <td>
                                            <input
                                                type="text"
                                                name="dayGen"
                                                disabled={energyType === 'Thermal'}
                                                value={stageData.dayGen}
                                                onChange={handleStageDataChange}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                name="cumGen_CY"
                                                disabled
                                                //disabled={energyType === 'Thermal'}
                                                //value={previousStageData.cumGen_CY + stageData.dayGen}
                                                value={Helper.ReturnCorrectValue(generationData.reportDate, stageData.dayGen, previousStageData?.cumGen_CY || 0)}
                                                onChange={handleStageDataChange}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                name="cumGen_CY_CM"
                                                disabled
                                                // disabled={energyType === 'Thermal'}
                                                // value={previousStageData.cumGen_CY_CM + stageData.dayGen}
                                                value={Helper.ReturnCorrectValue(generationData.reportDate, stageData.dayGen, previousStageData?.cumGen_CY_CM || 0)}
                                                onChange={handleStageDataChange}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                name="cumGen_PY"
                                                disabled
                                                // disabled={energyType === 'Thermal'}
                                                value={previousCumGen?.stagePreviousCumGenPY || 0}
                                                onChange={handleStageDataChange}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                name="cumGen_PY_CM"
                                                disabled
                                                //disabled={energyType === 'Thermal'}
                                                value={previousCumGen?.stagePreviousCumGenPYCM || 0}
                                                onChange={handleStageDataChange}
                                            />
                                        </td>
                                    </tr>
                                </tbody>

                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Export in MU</th>
                                        <th>Consumption in MU</th>
                                        <th>APC</th>
                                        <th>APC%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <input
                                                type="text"
                                                name="export_MU"
                                                value={stageData.export_MU}
                                                onChange={handleStageChange}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                name="consumptionColony_MU"
                                                value={stageData.consumptionColony_MU}
                                                onChange={handleStageChange}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                disabled
                                                name="apc"
                                                value={Helper.formatNumber(stageData.apc)}
                                                onChange={handleStageDataChange}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                disabled
                                                name="apcPer"
                                                value={Helper.formatNumber(stageData.apcPer)}
                                                onChange={handleStageDataChange}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>

                            <Button variant="primary" onClick={handleSubmit}>Update</Button>
                        </>}
                </div>
            </div>
        </>
    )
}

export default AddDailyGeneration;
