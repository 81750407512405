import React, { Suspense, useState, useEffect, lazy } from 'react';
import { useRoutes, Navigate, useNavigate } from 'react-router-dom';
import Root from './Root';
import LoadingComponent from '../components/Loader';
import { SidebarProvider } from '../context/sidebarContext';
import { WindowSizeProvider } from '../context/windowSizeContext';
import ForgotPassword from '../pages/auth/forgotPassword';
import ResetPassword from '../pages/auth/resetPassword';
import DailyGeneration from '../pages/dailyGeneration/DailyGeneration';
import AddDailyGeneration from '../pages/dailyGeneration/AddDailyGeneration';
import AddOutage from '../pages/outage/addOutage';
import Outage from '../pages/outage/outage';

const Station = lazy(() => import('../pages/station/station'));
const AddStation = lazy(() => import('../pages/station/addStation'));
const Users = lazy(() => import('../pages/user/users'));
const AddUser = lazy(() => import('../pages/user/addUser'));
const Stage = lazy(() => import('../pages/stage/stage'));
const AddStage = lazy(() => import('../pages/stage/addStage'));
const Unit = lazy(() => import('../pages/unit/unit'));
const AddUnit = lazy(() => import('../pages/unit/addUnit'));
const PITags = lazy(() => import('../pages/piTags/piTags'));
const CriticalCoalStock = lazy(() => import('../pages/settings/criticalCoalStock'));
const AddPITag = lazy(() => import('../pages/piTags/addPITags'));
const FuelStock = lazy(() => import('../pages/fuelStock/fuelStock'));
const AddFuelStock = lazy(() => import('../pages/fuelStock/addFuelStock'));
const FuelPrice = lazy(() => import('../pages/fuelPrice/fuelPrice'));
const AddFuelPrice = lazy(() => import('../pages/fuelPrice/addFuelPrice'));
const ChangePassword = lazy(() => import('../pages/auth/changePassword'));

const Topbar = React.lazy(() => import('../components/topbar'));
const Login = React.lazy(() => import('../pages/auth/login'));


// Not In Use 




type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => {
    // const [loginUser, setLoginUser] = useState(JSON.parse(localStorage.getItem('loginInUser') || '{}'));
    const loginUser = JSON.parse(localStorage.getItem('loginInUser') || '{}');
    const navigate = useNavigate();

    /* useEffect(() => {
        if (loginUser) {
            navigate('/stations');
        }
    }, [loginUser]) */

    return (
        <Suspense fallback={<LoadingComponent />}>
            <Component />
        </Suspense>
    )
};


// HOC for Navbar
const NavbarComponent = ({ component: Component, requiredRoles = ['Admin', 'CHP User', 'Finance User','Operation User'] }: LoadComponentProps) => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const [loginUser, setLoginUser] = useState(JSON.parse(localStorage.getItem('loginInUser') || '{}'));
    //console.log(loginUser, 'loginUser', requiredRoles);

    useEffect(() => {
        // Function to update window size
        const updateSize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        // Listen for resize events
        window.addEventListener('resize', updateSize);
        // Remove event listener on component unmount
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    if (loginUser && (requiredRoles?.includes(loginUser?.userRole))) {
        return (

            <Suspense fallback={<LoadingComponent />}>
                <SidebarProvider>
                    <WindowSizeProvider>
                        <div className='wrapper' style={{
                            width: windowSize.width,
                            height: windowSize.height,
                        }}>
                            <Topbar />
                            <Component />
                        </div>
                    </WindowSizeProvider>

                </SidebarProvider>
            </Suspense>
        )
    } else {
        localStorage.clear();
        return <Navigate to="/login" />;
    }


};

const AllRoutes = () => {
    return useRoutes(

        [
            { path: '/', element: <Root /> }, // DON'T REMOVE this is used to navigate user to respective panel after login 
            {
                // public routes
                path: '/',
                children: [
                    {
                        path: '',
                        children: [

                            { path: 'login', element: <LoadComponent component={Login} /> },
                        ],
                    },
                ],
            },
            {
                // auth protected routes
                path: '/',
                // element: <Root />,
                children: [
                    { path: 'changepassword', element: <NavbarComponent component={ChangePassword} /> },
                    { path: 'forgotpassword', element: <LoadComponent component={ForgotPassword} /> },
                    { path: 'resetpassword', element: <LoadComponent component={ResetPassword} /> },
                    { path: 'stations', element: <NavbarComponent component={Station} /> },
                    { path: 'stations/:stationCode', element: <NavbarComponent component={AddStation} /> },
                    { path: 'users', element: <NavbarComponent component={Users} /> },
                    { path: 'users/:userID', element: <NavbarComponent component={AddUser} /> },
                    { path: 'stations/:stationCode/stages', element: <NavbarComponent component={Stage} /> },
                    { path: 'stations/:stationCode/stages/:stageID', element: <NavbarComponent component={AddStage} /> },
                    { path: 'stations/:stationCode/units', element: <NavbarComponent component={Unit} /> },
                    { path: 'stations/:stationCode/units/:unitID', element: <NavbarComponent component={AddUnit} /> },
                    { path: 'pitags', element: <NavbarComponent component={PITags} /> },
                    { path: 'pitags/:piTagId', element: <NavbarComponent component={AddPITag} /> },
                    { path: 'configuration/criticalcoalstockdays', element: <NavbarComponent component={CriticalCoalStock} /> },
                    { path: 'fuelstock', element: <NavbarComponent requiredRoles={['Admin', 'CHP User']} component={FuelStock} /> },
                    { path: 'fuelstock/:stockId', element: <NavbarComponent requiredRoles={['Admin', 'CHP User']} component={AddFuelStock} /> },
                    { path: 'fuelprice', element: <NavbarComponent requiredRoles={['Admin', 'Finance User']} component={FuelPrice} /> },
                    { path: 'fuelprice/:priceId', element: <NavbarComponent requiredRoles={['Admin', 'Finance User']} component={AddFuelPrice} /> },
                    { path: 'daygen', element: <NavbarComponent requiredRoles={['Admin','Operation User']} component={DailyGeneration} /> },
                    { path: 'daygen/:id', element: <NavbarComponent requiredRoles={['Admin','Operation User']} component={AddDailyGeneration} /> },
                    { path: 'outage', element: <NavbarComponent requiredRoles={['Admin','Operation User']} component={Outage} /> },
                    { path: 'outage/:outageId', element: <NavbarComponent requiredRoles={['Admin','Operation User']} component={AddOutage} /> },


                ]
            },
        ],




    );
};
export { AllRoutes };
