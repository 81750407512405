import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import classNames from 'classnames';

const DeleteModal = ({modal,toggle,handleDelete,type,userName}) => {
    const DeleteFunc = () => {
        handleDelete();
        toggle()
    }
    return (
        <Modal show={modal} onHide={toggle}>
            <Modal.Header
                onHide={toggle}
                closeButton
                className={classNames('modal-colored-header', 'bg-danger')}>
                <h4 className="modal-title text-light">Delete {type}</h4>
            </Modal.Header>
            <Modal.Body>
                <h5 className="mt-0">Are you sure want to delete {type} { ( userName  &&  userName)} ?</h5>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={toggle}>
                    Cancel
                </Button>{' '}
                <Button variant="danger" onClick={DeleteFunc}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteModal;