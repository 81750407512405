import React, { useState, useEffect } from 'react'
import { getDailyGeneration } from './dailyGenerationService';
import { getStations } from '../station/stationService';
import DeleteModal from '../../components/DeleteModal';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Col, Row, Modal, Form, Button } from 'react-bootstrap';
import Table from '../../components/Table';
import Helper from '../../Utils/helper';
import toast from 'react-hot-toast';
import Navbar from '../../components/navbar';


const DailyGeneration = () => {

    const sizePerPageList = [
        {
            text: '10',
            value: 10,
        },
        {
            text: '20',
            value: 20,
        },
    ];

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setSearchFields((prev) => ({
            ...prev,
            [name]: value,

        }));
    };

    const today = new Date().toISOString().split('T')[0];

    const ActionColumn = (row) => {
        let rowOriginal = row.row.original;
        return (
            <>
                <Link className="action-icon"
                    to={`/daygen/${rowOriginal.rid}`}
                >
                    {' '}
                    <i className="fas fa-edit"></i>
                </Link>
            </>
        );
    };


    const [loginUser, setLoginUser] = useState(JSON.parse(localStorage.getItem('loginInUser')));
    // get all columns
    const columns = [
        {
            Header: 'Date',
            accessor: 'reportDate',
            sort: true,
        },
        {
            Header: 'Station',
            accessor: 'station',
            sort: true,
        },
        {
            Header: 'Stage',
            accessor: 'stage',
            sort: true,
            Cell: ({ value }) => value ? value : 'NA'
        },
        {
            Header: 'Day Gen MU',
            accessor: 'dayGen',
            sort: true,
            Cell: (row) => Helper.formatNumber(row.value)
        },
        {
            Header: 'CumGen CY',
            accessor: 'cumGen_CY',
            sort: true,
            Cell: (row) => Helper.formatNumber(row.value)
        },

        {
            Header: 'CumGen CM',
            accessor: 'cumGen_CY_CM',
            sort: true,
            Cell: (row) => Helper.formatNumber(row.value)


        },
        {
            Header: 'CumGen PY',
            accessor: 'cumGen_PY',
            sort: true,
            Cell: (row) => Helper.formatNumber(row.value)
        },

        {
            Header: 'CumGen PY CM',
            accessor: 'cumGen_PY_CM',
            sort: true,
            Cell: (row) => Helper.formatNumber(row.value)

        },
        {
            Header: 'Export',
            accessor: 'export_MU',
            sort: true,
            Cell: (row) => Helper.formatNumber(row.value)
        },

        {
            Header: 'Consumption',
            accessor: 'consumptionColony_MU',
            sort: true,
            Cell: (row) => Helper.formatNumber(row.value)


        },
        {
            Header: 'APC',
            accessor: 'apc',
            sort: true,
            Cell: (row) => Helper.formatNumber(row.value)
        },

        {
            Header: 'APC%',
            accessor: 'apcPer',
            sort: true,
            Cell: (row) => Helper.formatNumber(row.value)


        },

        {
            Header: 'Action',
            accessor: 'id',
            Cell: ActionColumn,
        },

    ];

    const [searchFields, setSearchFields] = useState({
        stationCode: loginUser?.stationCode || "",
        fromDate: null,
        toDate: null,
    });

    const [dailyGenerations, setDailyGenerations] = useState([]);
    const [stations, setStations] = useState([]);

    const fetchStations = async () => {
        try {
            const response = await getStations();
            if (response.data.success) {
                let data = response.data.data;
                console.log(data);
                if (loginUser?.stationCode) {
                    data = data.filter((station) => station.stationCode === loginUser.stationCode);
                    fetchDailyGenerations();
                }
                setStations(data);
            }

        } catch (e) {
            console.log(e);
        }
    }

    const fetchDailyGenerations = async () => {
        try {
            const response = await getDailyGeneration(searchFields?.stationCode, searchFields?.fromDate, searchFields?.toDate);
            if (response.data.success) {
                const data = response.data.data;
                console.log(data);
                setDailyGenerations(data);
            }

        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchStations();
        fetchDailyGenerations();
    }, []);


    return (
        <>
            <Navbar />
            <div className='content' style={{ width: '100%' }}>
                <div id="page-containter">
                    <Row>
                        <Col xs={12}>
                            <Card className='custom-card'>
                                <Card.Body>
                                    <Row className='mb-2'>
                                        <Col>
                                            <h2 className="page-title">Daily Generation</h2>
                                        </Col>
                                        <Col sm={4}>
                                            <div className="text-sm-end btn-employee">
                                                <Link to={`/daygen/add`} className="btn btn-primary float-right transformy39px">
                                                    <i className="dripicons-plus me-2"></i> Add New Generation
                                                </Link>

                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Row>
                                                <Col>
                                                    <Form.Group controlId="formStationID" className='mb-2'>
                                                        <Form.Select
                                                            value={searchFields?.stationCode}
                                                            name="stationCode"
                                                            onChange={handleChangeSearch}
                                                        >
                                                            <option value={""}
                                                                disabled={loginUser?.stationCode ? true : false}
                                                            >All Station</option>
                                                            {
                                                                stations.map((station) => (
                                                                    <option key={station.stationCode} value={station.stationCode}>{station.stationName}</option>
                                                                ))
                                                            }
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    {/* input type of date fornmat */}
                                                    <Form.Group controlId="formReportDate" className='mb-2'>
                                                        <Form.Control
                                                            type="date"
                                                            placeholder="From Date"
                                                            value={searchFields.fromDate}
                                                            name="fromDate"
                                                            onChange={handleChangeSearch}
                                                            max={searchFields?.toDate}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group controlId="formReportDate" className='mb-2'>
                                                        <Form.Control
                                                            type="date"
                                                            placeholder="To Date"
                                                            value={searchFields.toDate}
                                                            name="toDate"
                                                            onChange={handleChangeSearch}
                                                            min={searchFields?.fromDate}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Button variant="primary " className=" btn-md"
                                                        onClick={() => {
                                                            fetchDailyGenerations();
                                                        }}>Search</Button>

                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>

                                    <Table
                                        columns={columns}
                                        data={dailyGenerations}
                                        pageSize={10}
                                        sizePerPageList={sizePerPageList}
                                        isSortable={true}
                                        pagination={true}
                                        isSearchable={false}
                                        theadClass="table-light"
                                        searchBoxClass="mb-5"
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>

                {/* {deleteModal && <DeleteModal modal={deleteModal} toggle={handleCloseDelete} type={'Fuel Price'}
                    handleDelete={OnDelete} userName={fuelPrice.station + `(${fuelPrice.stage})` + "-" + Helper.formatDate(fuelPrice?.reportDate)} />} */}
            </div>

        </>
    )
}

export default DailyGeneration