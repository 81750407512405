// @flow
import React from 'react';
import Routes from './routes/Routes';
import { Toaster } from 'react-hot-toast';


type AppProps = {};
const App = (props: AppProps): React$Element<any> => {

    return (
        <>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
           {/*  <APIProvider apiKey='AIzaSyDKMm7_KyXOcgBZLJGsR1pRrhkKGB5guCk' > */}
                <Routes></Routes>
          {/*   </APIProvider> */}

        </>
    )
};

//Exporting APP
export default App;