import API from '../../API/API-AUTH'
import axios from 'axios';


export const getOutages = (stationCode,fromDate="",toDate="") => {
    let url = `/adminoutage?stationCode=${stationCode}`;
    if(fromDate){
        url += `&fromDate=${fromDate}`;
    }
    if(toDate){
        url += `&toDate=${toDate}`;
    }

    return new Promise((resolve, reject) => {
        API.get(`${url}`)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const getOutage = (id) => {
    return new Promise((resolve, reject) => {
        API.get(`/adminoutage/${id}`)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const addOutage = (body) => {
    return new Promise((resolve, reject) => {
        API.post(`/adminoutage`, body)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const updateOutage = (id, body) => {
    return new Promise((resolve, reject) => {
        API.put(`/adminoutage/${id}`, body)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const deleteOutage = (id) => {
    return new Promise((resolve, reject) => {
        API.delete(`/adminoutage/${id}`)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const getStationStageUnitDropdown = () => {
    return new Promise((resolve, reject) => {
        API.get(`/masters/stations/dropdown`)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export const uploadFile = (formData) => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const loginUser = localStorage.getItem('loginInUser');
	const local_store_token = loginUser ? JSON.parse(loginUser).token : '';
    return axios.post(`${baseUrl}/uploads`, formData,{
        headers: {
            'Authorization': `Bearer ${local_store_token}`, // Add Bearer token to Authorization header
        },
    })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            throw error;
        });
};


