import React, { useEffect, useState } from 'react'
import { login as loginAPI } from './authService';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios';
import JPLLogo from '../../assests/jpl-logo.png'

const ResetPassword = () => {
    const location = useLocation();
    // Function to extract query parameters
    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };
    const queryParams = getQueryParams(location.search);
    const code = queryParams.get('code');
    const email = queryParams.get('email');



    const loginInitialState = {
        email: email || '',
        password: '',
        resetCode: code || '',
    }

    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const [login, setLogin] = useState(loginInitialState);
    const [error, setError] = useState(false);

    // onchange function for above state
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLogin({
            ...login,
            [name]: value
        });
    };

    // onsubmit function for above state
    const handleSubmit = async (e) => {
        e.preventDefault();
        //console.log(login);

        if (!login.email || !login.password) {
            setError(true)
            return;
        }
        else {
            const reqBody = {
                email: login.email || email,
                password: login.password,
                resetCode: login.resetCode || code,
            };



            const toastId = toast.loading('loading ...');
            try {
                const response = await axios.post(`${process.env.REACT_APP_AUTH_API_URL}/auth/resetpassword`, reqBody, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.status === 200) {
                    toast.success('Password reset successfully', { id: toastId });
                    navigate('/login');
                }

            } catch (e) {
                console.log(e);
                toast.error(e?.response?.data?.message || e?.data?.message, { id: toastId });
                return;
            }
        }


    };

    const fetchEmail = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_AUTH_API_URL}/auth/email`, {
                resetCode: code
            });

            if (response.status === 200) {
                setLogin((prev) => ({
                    ...prev,
                    email: response?.data?.data?.email
                }))
            }
        }
        catch (e) {
            console.log(e);
            toast.error(e?.response?.data?.message || e?.data?.message || 'Something went wrong');
        }
    }



    useEffect(() => {
        fetchEmail();
    }, [code])

    return (
        <>
            <div class="container-fluid bg">
                <div class="row login-outer">
                    <div class="col-md-5 col-sm-12 login-left">
                        <img src="static/plant-banner.png" loading="lazy" class="login-left-img" alt="login" />

                    </div>
                    <div class="col-md-7 col-sm-12">
                        <div className='login-log'>
                            <img src={JPLLogo} loading='lazy' alt='JPL Logo' className='jpl-logo' />
                        </div>

                        <div class="card login-box">
                            <div class="card-body">
                                <h2 class="card-title  black-color">Plant Live</h2>
                                <p>Real Time Analytics App</p>

                                <div class="mb-3">
                                    <input type="email" placeholder="Enter Email"
                                        name='email' required value={login.email}
                                        disabled
                                        onChange={(e) => {
                                            e.target.value = e.target.value.replace(/[^a-zA-Z0-9@.]/g, '').trim()
                                            handleInputChange(e)
                                        }}
                                        class="form-control black-color" id="email"
                                        aria-describedby="emailHelp" />
                                    <small className='error ml-2'>{(error && !login.email) ? "Email is required" : ""}</small>
                                </div>
                                <div class="mb-3 position-relative ">
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        value={login.password}
                                        name='password'
                                        onChange={handleInputChange}
                                        required
                                        placeholder="Password" class="form-control position-relative" id="password" />

                                    <div className='password-visibility-login black-color'>
                                        {showPassword ?
                                            <i class="fas fa-eye-slash" onClick={togglePasswordVisibility}></i>
                                            :
                                            <i class="fas fa-eye" onClick={togglePasswordVisibility}></i>
                                        }
                                    </div>
                                    <small className='error ml-2'>{(error && !login.password) ? "Password is required" : ""}</small>

                                </div>
                                {/* <p class="reminder mt-2">
                                         <Link  className="disable-pointer forgot-link">Forgot Password?</Link>
                                       
                                    </p> */}
                                <button type='button' onClick={handleSubmit} class="btn btn-primary ml-2">Submit</button>

                                {/*  <div class="mb-3 form-check mt-10">
                                        <input type="checkbox" name='rememberMe'
                                            checked={login.rememberMe}
                                            onChange={(e) => {
                                                setLogin((prev) => ({
                                                    ...prev,
                                                    rememberMe: e.target.checked

                                                }))
                                            }} class="form-check-input" id="rememberMe" />
                                        <label class="form-check-label" for="rememberMe">Remember me</label>
                                    </div>
                                    <div class="mt-3"></div> */}

                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default ResetPassword