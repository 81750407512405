import API from '../../API/API-AUTH'
import axios from 'axios';

export const getStations = () => {
    return new Promise((resolve, reject) => {
        API.get(`/masters/stations`)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const getStation = (id) => {
    return new Promise((resolve, reject) => {
        API.get(`/masters/stations/${id}`)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const addStations = (body) => {
    return new Promise((resolve, reject) => {
        API.post(`/masters/stations`, body)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const updateStations = (id, body) => {
    return new Promise((resolve, reject) => {
        API.put(`/masters/stations/${id}`, body)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const deleteStations = (id) => {
    return new Promise((resolve, reject) => {
        API.delete(`/masters/stations/${id}`)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const getStationStageUnitDropdown = () => {
    return new Promise((resolve, reject) => {
        API.get(`/masters/stations/dropdown`)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const getStationByCode = (code) =>{
    return new Promise((resolve, reject) => {
        API.get(`/masters/stations/code?stationCode=${code}`)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });

}

export const changeStatus = (id,status) =>{
    return new Promise((resolve, reject) => {
        API.patch(`/users/${id}`,{
            "status":status
        })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const UploadRequest = async (file) => {
    let formData = new FormData();
    console.log(file)
    formData.append('file', file);

    try{
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data', 
            },
        });

        return response;
    }
    catch{

    }

    // api call to pass formdata to backend with header as multipart formadata
   

};