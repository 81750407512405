import { Navigate } from 'react-router-dom';
// NOTE - You can fetch from server and return here as well
const Root = () => {
    const loggedInUser = JSON.parse(localStorage.getItem('loginInUser'));
   // const keepMeLoggedIn = localStorage.getItem('keepMeLoggedIn');
    if (loggedInUser  && loggedInUser?.userRole == 'Admin') {
        return <Navigate to={`/users`} />;
    }
    return <Navigate to={`/login`} state={{ refreshOnce: true }} />;
};

export default Root;
