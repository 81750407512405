class Helper {
    static formatDate = (dateInput = new Date()) => {
        const currentDate = new Date(dateInput);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return currentDate.toLocaleDateString('en-IN', options);
    };

    static formatTime = (timestamp) => {
        const date = new Date(timestamp);
        //  remove 330 minutes from date becuase date recevied from backend is right and new Date Add is adding 330 minutes
        date.setMinutes(date.getMinutes() - 330);
        return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
    };

    static formatNumber = (number) => {
        if (!number) {
            return 0;
        }

        return Number(number)?.toFixed(2);
    }

    static OnlyAlphanumeric = (input) => {
        return input.replace(/[^a-zA-Z0-9\s]/g, '');
    };

    // alphanumeric with dot and @


    // for email input only 
    static OnlyEmail = (input) => {
        return input.replace(/[^a-zA-Z0-9@.]/g, '');
    };

    static OnlyNumericwithDotMinus = (input) => {
        return input.replace(/[^0-9.-]/g, '');
    };

    // numeric only 
    static OnlyNumeric = (input) => {
        return input.replace(/[^0-9]/g, '');
    };

    static OnlyNumericwithDot = (input) => {
        return input.replace(/[^0-9.]/g, '');
    };

    static OnlyAlphabet = (input) => {
        return input.replace(/[^a-zA-Z\s]/g, '');
    };

    static OnlyMobile = (input) => {
        return input.replace(/[^0-9+]/g, '');
    };

    static getCurrentMonthYear = () => {
        const currentDate = new Date();
        const options = { month: 'long', year: 'numeric' };
        return currentDate.toLocaleDateString('en-IN', options);
    }

    //function to filter a list based on it's date 

    static isInvalidEmail(email) {
        // Combine the regex patterns for various invalid email conditions
        const invalidEmailPattern = /(^[^@]+@[^@]+(\.[^@]+)+$)|([^@]*@[^@]*@[^@]*)|([^a-zA-Z0-9@._-])|(\.{2,})|(^\.|\.$)|(@[^.]+)$/;
        // Check if the email does not match the valid email pattern
        return !invalidEmailPattern.test(email);
    }

    // validation for url 
    static isValidURL(url) {
        console.log(url);
        const urlPattern = new RegExp(
            '^(https?:\\/\\/)' + // protocol
            '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.?)+[a-zA-Z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-zA-Z\\d_]*)?$', 'i' // fragment locator
        );
        console.log(urlPattern.test(url));

        return urlPattern.test(url);
    }

    // allow only valid url
    static OnlyURL = (input) => {
        return input.replace(/[^a-zA-Z0-9-./:]/g, '');
    };

    static ReturnCorrectValue = (reportDate, value, previousValue) => {
        const date = new Date(reportDate);
        console.log(value, previousValue, "value, previousValue");
        if (date.getDate() === 1 && date.getMonth() === 4) {
            return Number(value).toFixed(2);
        } else {
            return (Number(value) + Number(previousValue)).toFixed(2);
        }
    }

    static YesterdayDate = (reportDate) => {
        let newDate = new Date();
        if (reportDate) {
            newDate = new Date(reportDate + 'T00:00:00Z'); // Ensure the date is in UTC   
        }        // Subtract one day (in milliseconds)
        newDate.setUTCDate(newDate.getUTCDate() - 1);
        // Format the newDate back to 'YYYY-MM-DD' format
        let year = newDate.getUTCFullYear();
        let month = ('0' + (newDate.getUTCMonth() + 1)).slice(-2); // Months are zero-indexed
        let day = ('0' + newDate.getUTCDate()).slice(-2);
        let formattedDate = `${year}-${month}-${day}`;

        return formattedDate;
    }

}

export default Helper;