import axios from 'axios';
let token;

const instance = axios.create({
	baseURL:  process.env.REACT_APP_API_URL,
});

// Add a request interceptor
instance.interceptors.request.use(
	(config) => {
		const loginUser = localStorage.getItem('loginInUser');
		const local_store_token = loginUser ? JSON.parse(loginUser).token : '';
		if (local_store_token && typeof local_store_token != undefined && local_store_token != '') {
			token = local_store_token;
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		config.headers['Content-Type'] = 'application/json';
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// Updated response interceptor
instance.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const loginUser = localStorage.getItem('loginInUser');
		console.log('error', error);
		if ((error?.response && (error.response?.status === 401 || (!loginUser && error?.response?.status === 400)))) {
			localStorage.clear();
			window.location.href = '/';
			return Promise.reject(error.response);
		} else {
			return Promise.reject(error.response);
		}
	}
)


export default instance;
