import API from '../../API/API-AUTH'

export const getDailyGeneration = (stationCode,fromDate="",toDate="") => {
    let url = `/daygen?stationCode=${stationCode}`;
    if(fromDate){
        url += `&fromDate=${fromDate}`;
    }
    if(toDate){
        url += `&toDate=${toDate}`;
    }

    return new Promise((resolve, reject) => {
        API.get(`${url}`)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const AddDailyGenerations = (body) =>{
    return new Promise((resolve, reject) => {
        API.post(`/daygen`,body)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });

}

export const getDailyGenerationDetail = (reportDate, stationCode,stage) => {
    let url = `/daygen/detail?reportDate=${reportDate}&stationCode=${stationCode}`;
    if(stage)
        url += `&stage=${stage}`;
    return new Promise((resolve, reject) => {
        API.get(url)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const getDailyGenerationDetailById = (id) => {
    return new Promise((resolve, reject) => {
        API.get(`/daygen/${id}`)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const getPreviousCumGen = (reportDate, stationCode, stage) => {
    let url = `/daygen/previous?reportDate=${reportDate}&stationCode=${stationCode}`;
    if(stage)
        url += `&stage=${stage}`;
    return new Promise((resolve, reject) => {
        API.get(url)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
